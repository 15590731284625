import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthSlice } from '../../modules/profile/slice';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  selectAccessToken,
  selectErrors,
  selectMessage,
  selectProfileLoading,
} from '../../modules/profile/slice/selectors';
import { envConfig } from '../../../config/env.config';
import { Alerts } from '../../components/Alerts';
import { Constants } from '../../../constants';
import { TextInput } from '../../components/TextInput';
import { Button } from '../../components/Button';
import { Spinner } from '../../components/Spinner';
import { LoadingOverlay } from '../../components/LoadingOverlay';
import { getAuth, signInAnonymously, onAuthStateChanged } from 'firebase/auth';
import { logger } from '../../../utils/logger';
import { isEmptyString } from 'utils';

const log = logger().child({ module: 'Login' });

export function Login() {
  const { actions } = useAuthSlice();

  const dispatch = useDispatch();

  const accessToken = useSelector(selectAccessToken);
  const message = useSelector(selectMessage);
  const errors = useSelector(selectErrors);
  const loading = useSelector(selectProfileLoading);
  const history = useHistory();

  const [username, setUsername] = useState(envConfig.isDevMode ? 'steven' : '');
  const [password, setPassword] = useState(
    envConfig.isDevMode ? 'rekosistem' : '',
  );

  const signIn = () => {
    if (!isEmptyString(username) && !isEmptyString(password)) {
      dispatch(actions.setLoginData({ username, password }));
      dispatch(actions.loadUserData());
    }
  };

  const anonymouslyLogin = async () => {
    if (!isEmptyString(accessToken)) {
      signInAnonymously(getAuth())
        .then(userCredential => {
          // Anonymous user is signed in
          log.debug('Anonymous user ID:', userCredential.user.uid);
        })
        .catch(error => {
          log.debug('Anonymous sign-in error:', error);
        });
    }
  };

  useEffect(() => {
    if (!isEmptyString(accessToken)) anonymouslyLogin();
  }, [accessToken]);

  useEffect(() => {
    if (!isEmptyString(accessToken)) {
      const unsubscribe = onAuthStateChanged(getAuth(), user => {
        if (user) {
          log.debug('anonymously User is signed in:', user.uid);
          history.go(0);
        } else log.debug('User is signed out');
      });

      return () => unsubscribe();
    }
  }, [accessToken]);

  return (
    <>
      <div className="h-full flex">
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <div className={'flex items-center'}>
                <h2 className="mt-2 text-3xl font-extrabold text-dark">
                  {Constants.APP_NAME} Admin
                </h2>
              </div>
              <h2 className="mt-6 text-2xl font-extrabold text-gray-900">
                Sign in to your account!
              </h2>
            </div>

            <div className={'mt-4'}>
              {errors ? (
                <Alerts
                  title={message ? message : 'Error'}
                  type={'error'}
                  description={errors}
                />
              ) : null}
            </div>

            <div className="mt-8">
              <div className="mt-6">
                <form action="#" method="POST" className="space-y-6">
                  <TextInput
                    label={'Email'}
                    type={'top-label'}
                    placeholder={'you@example.com'}
                    onChange={e => {
                      setUsername(e.target.value);
                    }}
                    value={username}
                    isError={!!errors}
                    errorMessage={''}
                  />

                  <TextInput
                    label={'Password'}
                    type={'top-label'}
                    placeholder={'******'}
                    onChange={e => {
                      setPassword(e.target.value);
                    }}
                    value={password}
                    isError={!!errors}
                    errorMessage={''}
                    formType={'password'}
                  />

                  <div>
                    <Button
                      className={'mt-8 w-full flex justify-center'}
                      type={'primary'}
                      onClick={signIn}
                      disabled={loading}
                    >
                      Sign In
                      {loading ? (
                        <Spinner size={'small'} className={'ml-2'} />
                      ) : null}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://rekosistem.com/wp-content/uploads/2022/11/logo-animation-no-floor_1080x1080.gif"
            alt=""
          />
        </div>
        <LoadingOverlay isActive={loading} />
      </div>
    </>
  );
}
