import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './index';
import { removeDuplicates } from '../../../../utils/removeDuplicate';

const selectSlice = (state: RootState) => state.dropIn || initialState;

export const selectDropIn = createSelector([selectSlice], state => state);

export const selectTotalDropInReceive = createSelector(
  [selectSlice],
  state => state.totalDropInReceive,
);

export const selectTotalDropInSorted = createSelector(
  [selectSlice],
  state => state.totalDropInSorted,
);

export const selectTotalDropIn = createSelector(
  [selectSlice],
  state => state.totalDropIn,
);

export const selectDropInList = createSelector([selectSlice], state =>
  removeDuplicates(state.list, 'dropCode'),
);

export const selectDropInSearchList = createSelector([selectSlice], state =>
  removeDuplicates(state.listSearch, 'dropCode'),
);

export const selectDeleteSuccess = createSelector(
  [selectSlice],
  state => state.isDeleted,
);

export const selectWasteCategoryList = createSelector(
  [selectSlice],
  state => state.wasteCategory,
);

export const selectProductList = createSelector(
  [selectSlice],
  state => state.product,
);

export const selectDropInLoading = createSelector(
  [selectSlice],
  state => state.loading,
);

export const selectDropInError = createSelector(
  [selectSlice],
  state => state.error,
);

export const selectDropInPaginationParams = createSelector(
  [selectSlice],
  state => state.paginationParams,
);

export const selectDropCode = createSelector(
  [selectSlice],
  state => state.selectedDropCode,
);

export const selectDropInPagination = createSelector(
  [selectSlice],
  state => state.pagination,
);

export const selectDropInId = createSelector(
  [selectSlice],
  state => state.dropInId,
);

export const selectDropInDetail = createSelector(
  [selectSlice],
  state => state.data,
);

export const selectDropInMessage = createSelector(
  [selectSlice],
  state => state.message,
);

export const selectSubmitDropInSuccess = createSelector(
  [selectSlice],
  state => state.submitSuccess,
);

export const selectDeleteDropInSuccess = createSelector(
  [selectSlice],
  state => state.deleteSuccess,
);

export const selectDropInSortForm = createSelector(
  [selectSlice],
  state => state.formSort,
);

export const selectDropInWastetForm = createSelector(
  [selectSlice],
  state => state.formWaste,
);

export const selectDropInReceiveForm = createSelector(
  [selectSlice],
  state => state.formReceive,
);

export const selectDropInSorter = createSelector(
  [selectSlice],
  state => state.dropInSorter,
);

export const selectDropInGeneratedDropCode = createSelector(
  [selectSlice],
  state => state.generatedDropCode,
);

export const selectDropInOverview = createSelector(
  [selectSlice],
  state => state.dropInOverview,
);

export const selectDropCodeList = createSelector(
  [selectSlice],
  state => state.dropCodeList,
);

export const selectEprSortingDataList = createSelector(
  [selectSlice],
  state => state.eprSortingData,
);

export const selectSubmitEprSuccess = createSelector(
  [selectSlice],
  state => state.eprSortingFormSuccess,
);

export const selectEprReceivingFormDataList = createSelector(
  [selectSlice],
  state => state.eprReceiveForm,
);
