import { call, put, select, takeLatest } from 'redux-saga/effects';
import { profileActions as actions } from './index';
import { LoginData } from './types';
import {
  selectAccountId,
  selectAccountListPaginationParams,
  selectAddAccount,
  selectDeleteAccountId,
  selectFormEditAccount,
  selectLoginData,
  selectProfileId,
  selectRefreshToken,
} from './selectors';
import { selectApi } from '../../../../api/slice/selectors';
import { apiActions } from '../../../../api/slice';
import { logger } from '../../../../utils/logger';
import { PaginationParams } from '../../../../types/Pagination';
import {
  ACCOUNT_LIST,
  ACCOUNT_LIST_BY_STATUS,
  CREATE_ACCOUNT,
  DELETE_ACCOUNT,
  EDIT_ACCOUNT_DETAIL,
  GeneralSubmitResult,
  GET_ACCOUNT_DETAIL,
  GET_ROLE,
  LOGIN,
  REFRESH_TOKEN_ADMIN,
} from '../../../../api/api.types';
import { EditAccountFormType } from '../../../../types/User';
import {
  ApiKind,
  ApiMethod,
  isMultipart,
  isUco,
} from '../../../../config/global.config';

/**
 * Logger
 */
const log = logger().child({ module: 'MainApi' });

export function* loginFlow() {
  const body: LoginData = yield select(selectLoginData);
  const api = yield select(selectApi);

  if (body.username?.length === 0 || body.username === '') {
    // TODO ERROR MESSAGE ACTIONS
    log.error('username / password is empty');
    return;
  }

  try {
    const data = {
      email: body.username,
      password: body.password,
    };

    const response: GeneralSubmitResult = yield call(
      [api, 'generalApi'],
      ApiMethod.POST,
      !isMultipart,
      LOGIN,
      !isUco,
      data,
    );

    if (response.kind === ApiKind.OK) {
      const data = response.data.data as any;

      yield put(actions.setUserData(data));
      yield put(
        apiActions.setApiToken({
          accessToken: data.accessToken,
          refreshToken: data.refreshToken,
        }),
      );
      yield put(
        apiActions.saveToken({
          accessToken: data.accessToken,
          refreshToken: data.refreshToken,
          id: data.id,
        }),
      );
    } else {
      yield put(
        actions.loginFailed({
          errors: response.response?.errors,
          data: response.response?.data,
          message: response.response?.message,
          status: response.response?.status,
        }),
      );
    }
  } catch (err: any) {
    log.error(err);
  }
}

export function* refreshTokenFlow() {
  const refreshToken: string = yield select(selectRefreshToken);
  const api = yield select(selectApi);

  try {
    const data = {
      refreshToken,
    };
    const response: GeneralSubmitResult = yield call(
      [api, 'generalApi'],
      ApiMethod.POST,
      !isMultipart,
      REFRESH_TOKEN_ADMIN,
      !isUco,
      data,
    );

    if (response.kind === ApiKind.OK) {
      const data = response.data.data;
      yield put(
        apiActions.setApiToken({
          accessToken: data.accessToken,
          refreshToken: data.refreshToken,
        }),
      );
      yield put(
        apiActions.saveToken({
          accessToken: data.accessToken,
          refreshToken: data.refreshToken,
          id: data.id,
        }),
      );
    } else {
      yield put(
        actions.loadRefreshTokenFailed({
          errors: response.response?.errors,
          data: response.response?.data,
          message: response.response?.message,
          status: response.response?.status,
        }),
      );
    }
  } catch (err: any) {
    log.error(err);
  }
}

// Account manager
function* getAccountList() {
  const { page, limit }: PaginationParams = yield select(
    selectAccountListPaginationParams,
  );
  const api = yield select(selectApi);

  log.info('get account list');

  try {
    const params = {
      page,
      limit,
    };
    const response: GeneralSubmitResult = yield call(
      [api, 'generalApi'],
      ApiMethod.GET,
      !isMultipart,
      ACCOUNT_LIST,
      !isUco,
      params,
    );
    if (response.kind === ApiKind.OK) {
      const data = response.data.data;
      yield put(actions.accountListLoaded(data));
    }
  } catch (err: any) {
    yield put(actions.accountListError(err));
  }
}

function* getAccountListSearch() {
  const { page, limit, keyword }: PaginationParams = yield select(
    selectAccountListPaginationParams,
  );
  const api = yield select(selectApi);

  log.info('get account list by keyword');

  try {
    const params = {
      page,
      limit,
      keyword,
    };
    const response: GeneralSubmitResult = yield call(
      [api, 'generalApi'],
      ApiMethod.GET,
      !isMultipart,
      ACCOUNT_LIST,
      !isUco,
      params,
    );

    if (response.kind === ApiKind.OK) {
      const data = response.data.data;
      yield put(actions.accountListLoaded(data));
    }
  } catch (err: any) {
    yield put(actions.accountListError(err));
  }
}

function* getAccountListByStatus() {
  const { page, limit, statusKey }: PaginationParams = yield select(
    selectAccountListPaginationParams,
  );
  const api = yield select(selectApi);

  log.info('get account list by status', statusKey);

  try {
    const params = {
      page,
      limit,
      isActive: statusKey,
    };
    const response: GeneralSubmitResult = yield call(
      [api, 'generalApi'],
      ApiMethod.GET,
      !isMultipart,
      ACCOUNT_LIST_BY_STATUS,
      !isUco,
      params,
    );

    if (response.kind === ApiKind.OK) {
      const data = response.data.data;
      yield put(actions.accountListByStatusLoaded(data));
    }
  } catch (err: any) {
    yield put(actions.accountListByStatusError(err));
  }
}

function* getAccountDetail() {
  const id: any = yield select(selectAccountId);
  const api = yield select(selectApi);

  log.info('get account detail');

  try {
    const response: GeneralSubmitResult = yield call(
      [api, 'generalApi'],
      ApiMethod.GET,
      !isMultipart,
      GET_ACCOUNT_DETAIL + id,
      !isUco,
    );
    if (response.kind === ApiKind.OK) {
      const data = response.data;
      yield put(actions.accountDetailLoaded(data));
    }
  } catch (err: any) {
    yield put(actions.accountDetailError(err));
  }
}

function* getProfileDetail() {
  const id: any = yield select(selectProfileId);
  const api = yield select(selectApi);

  log.info('get profile detail');

  try {
    const response: GeneralSubmitResult = yield call(
      [api, 'generalApi'],
      ApiMethod.GET,
      !isMultipart,
      GET_ACCOUNT_DETAIL + id,
      !isUco,
    );
    if (response.kind === ApiKind.OK) {
      const data = response.data;
      yield put(actions.profileDetailLoaded(data));
    }
  } catch (err: any) {
    yield put(actions.profileDetailError(err));
  }
}

function* putEditAccount() {
  const Form: EditAccountFormType = yield select(selectFormEditAccount);
  const id: number = yield select(selectAccountId);

  const api = yield select(selectApi);

  log.info('submit edit account with: ', Form);

  try {
    const response: GeneralSubmitResult = yield call(
      [api, 'generalApi'],
      ApiMethod.PUT,
      !isMultipart,
      EDIT_ACCOUNT_DETAIL + id,
      !isUco,
      Form,
    );

    if (response.kind === ApiKind.OK) {
      const data = response.data;
      yield put(actions.submitEditAccountSuccess(data));
    } else {
      yield put(
        actions.submitEditAccountError({
          errors: true,
          data: response.response?.data,
          message: response.response?.message,
          status: response.response?.status,
        }),
      );
    }
  } catch (err: any) {
    yield put(actions.submitEditAccountError(err));
  }
}

function* postAddAccount() {
  const Form: EditAccountFormType = yield select(selectAddAccount);

  const api = yield select(selectApi);

  log.info('submit add account with: ', Form);

  try {
    const response: GeneralSubmitResult = yield call(
      [api, 'generalApi'],
      ApiMethod.POST,
      !isMultipart,
      CREATE_ACCOUNT,
      !isUco,
      Form,
    );

    if (response.kind === ApiKind.OK) {
      const data = response.data;
      yield put(actions.submitAddAccountSuccess(data));
    } else {
      yield put(
        actions.submitAddAccountError({
          errors: true,
          data: response.response?.data,
          message: response.response?.message,
          status: response.response?.status,
        }),
      );
    }
  } catch (err: any) {
    yield put(actions.submitAddAccountError(err));
  }
}

function* getRole() {
  const api = yield select(selectApi);

  log.info('get role');

  try {
    const response: GeneralSubmitResult = yield call(
      [api, 'generalApi'],
      ApiMethod.GET,
      !isMultipart,
      GET_ROLE,
      !isUco,
    );
    if (response.kind === ApiKind.OK)
      yield put(actions.roleLoaded(response.data));
  } catch (err: any) {
    yield put(actions.roleError(err));
  }
}

function* deleteAccount() {
  const id: number = yield select(selectDeleteAccountId);
  const api = yield select(selectApi);

  log.info('delete account');

  try {
    const response: GeneralSubmitResult = yield call(
      [api, 'generalApi'],
      ApiMethod.DELETE,
      !isMultipart,
      DELETE_ACCOUNT + id,
      !isUco,
    );
    if (response.kind === ApiKind.OK) {
      const data = response.data;
      yield put(actions.submitDeleteAccountSuccess(data));
    }
  } catch (err: any) {
    yield put(actions.submitDeleteAccountError(err));
  }
}

export function* authSaga() {
  yield takeLatest(actions.loadRefreshToken.type, refreshTokenFlow);
  yield takeLatest(actions.loadUserData.type, loginFlow);
  yield takeLatest(actions.loadAccountList.type, getAccountList);
  yield takeLatest(
    actions.loadAccountListByStatus.type,
    getAccountListByStatus,
  );
  yield takeLatest(actions.loadAccountListSearch.type, getAccountListSearch);
  yield takeLatest(actions.loadAccountDetail.type, getAccountDetail);
  yield takeLatest(actions.loadProfileDetail.type, getProfileDetail);
  yield takeLatest(actions.submitEditAccount.type, putEditAccount);
  yield takeLatest(actions.submitAddAccount.type, postAddAccount);
  yield takeLatest(actions.submitDeleteAccount.type, deleteAccount);
  yield takeLatest(actions.loadRole.type, getRole);
}
