import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { DropInSaga } from './saga';
import { DropInState } from './types';
import {
  DropCodeType,
  DropInOverviewType,
  DropInReceiveParamType,
  DropInSortParamType,
  DropInType,
  DropWasteParamType,
  SorterType,
  WasteCategoryGroupType,
  Pagination,
  PaginationParams,
  EprPartnerSortFormItemType,
  EprPartnerReceiveFormItemType,
} from 'types';
import { paginationDefault, paginationParamsDefault } from '../../pagination';
import { GeneralDataResponse } from '../../../../api/api.types';
import { removeDuplicatesById } from 'utils';

export const DropInDetailDefault: DropInType = {
  createdAt: '',
  dropCode: '',
  dropStationId: '',
  evidenceUrl: '',
  inorganicPackage: 0,
  notes: '',
  programType: '',
  status: '',
  transferredAt: '',
  ucoContainer: 0,
  updatedAt: '',
  voucher: '',
  userId: '',
  userName: '',
  receivedAt: '',
  sortDate: '',
  nestlePackage: 0,
  eprPartnerSummaries: [],
};

export const eprSortingFormDefaultValue = {
  wasteCode: '',
  eprWasteList: [],
};

export const initialState: DropInState = {
  list: [],
  listSearch: [],
  dropCodeList: [],
  selectedDropCode: '',
  totalDropInReceive: 0,
  totalDropInSorted: 0,
  totalDropIn: 0,
  data: undefined,
  dropInForm: undefined,
  loading: false,
  error: null,
  paginationParams: paginationParamsDefault,
  pagination: paginationDefault,
  message: '',
  statusSuccess: false,
  submitSuccess: false,
  deleteSuccess: false,
  dropInId: 0,
  selectedUserId: 0,
  formSort: undefined,
  formWaste: undefined,
  formReceive: undefined,
  wasteCategory: [],
  product: [],
  dropInSorter: [],
  generatedDropCode: '',
  isDeleted: false,
  dropInOverview: undefined,
  eprSortingData: [],
  eprSortingForm: eprSortingFormDefaultValue,
  eprSortingFormSuccess: false,
  eprReceiveForm: [],
};

const slice = createSlice({
  name: 'dropIn',
  initialState,
  reducers: {
    setPaginationParams(state, action: PayloadAction<PaginationParams>) {
      state.paginationParams = action.payload;
    },
    setDropInSelectedId(state, action: PayloadAction<number>) {
      state.dropInId = action.payload;
    },
    setSelectedDropCode(state, action: PayloadAction<string>) {
      state.selectedDropCode = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setDropInForm(state, action: PayloadAction<DropInType>) {
      state.dropInForm = action.payload;
    },
    setDropInList(state, action: PayloadAction<DropInType>) {
      state.list = removeDuplicatesById([...state.list, action.payload]);
    },
    setDropInSearch(state, action: PayloadAction<DropInType>) {
      state.listSearch = removeDuplicatesById([...state.list, action.payload]);
    },
    setTotalDropInReceive(state, action: PayloadAction<any>) {
      state.totalDropInReceive = action.payload;
    },
    setTotalDropInSorted(state, action: PayloadAction<any>) {
      state.totalDropInSorted = action.payload;
    },
    setTotalDropIn(state, action: PayloadAction<any>) {
      state.totalDropIn = action.payload;
    },
    setProduct(state, action: PayloadAction<any>) {
      state.product = [...state.product, action.payload];
    },
    setWasteCategoryGroups(
      state,
      action: PayloadAction<WasteCategoryGroupType[]>,
    ) {
      state.wasteCategory = action.payload;
    },
    setWasteParam(state, action: PayloadAction<DropWasteParamType>) {
      state.formWaste = action.payload;
    },
    resetDropInDetail(state) {
      state.data = undefined;
    },
    resetDropInList(state) {
      state.list = [];
    },
    resetDropInSearch(state) {
      state.listSearch = [];
    },
    resetDropInError(state) {
      state.error = '';
      state.message = '';
      state.submitSuccess = false;
    },
    resetDropInSuccess(state) {
      state.error = '';
      state.message = '';
      state.submitSuccess = false;
    },

    loadDropInList(state) {
      state.loading = true;
      state.error = null;
      state.list = [];
    },
    listLoaded(
      state,
      action: PayloadAction<{
        pagination: Pagination;
        data: DropInType[];
      }>,
    ) {
      state.loading = false;
      state.error = null;
      state.pagination = action.payload.pagination;
      state.list = action.payload.data;
    },
    listError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.error;
      state.list = [];
    },

    loadDropInDetail(state) {
      state.loading = true;
      state.error = null;
      state.list = [];
    },
    dropInDetailLoaded(state, action: PayloadAction<DropInType>) {
      state.loading = false;
      state.error = null;
      state.data = action.payload;
    },
    dropInDetailError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.error;
      state.data = DropInDetailDefault;
    },

    deleteDropIn(state) {
      state.loading = true;
      state.error = null;
      state.isDeleted = false;
    },
    deleteDropInSuccess(state, action: PayloadAction<boolean>) {
      state.loading = false;
      state.error = null;
      state.isDeleted = action.payload;
    },
    deleteDropInError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.error;
      state.isDeleted = false;
    },
    resetDeleteDropIn(state) {
      state.loading = true;
      state.error = null;
      state.isDeleted = false;
    },

    loadSorter(state) {
      state.loading = true;
      state.error = null;
      state.dropInSorter = [];
    },
    loadedSorter(state, action: PayloadAction<SorterType[]>) {
      state.loading = false;
      state.error = null;
      state.dropInSorter = action?.payload ?? [];
    },
    errorLoadSorter(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
      state.dropInSorter = [];
    },

    loadGeneratedDropCode(state) {
      state.loading = true;
      state.error = null;
      state.generatedDropCode = '';
    },
    loadedGeneratedDropCode(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = null;
      state.generatedDropCode = action.payload;
    },
    errorGeneratedDropCode(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
      state.generatedDropCode = '';
    },

    setUserId(state, action: PayloadAction<any>) {
      state.selectedUserId = action.payload;
    },

    setSortParam(state, action: PayloadAction<DropInSortParamType>) {
      state.formSort = action.payload;
    },
    submitSort(state) {
      state.loading = true;
      state.error = null;
      state.message = '';
      state.submitSuccess = false;
    },
    submitSortSuccess(state, action: PayloadAction<GeneralDataResponse>) {
      state.loading = false;
      state.submitSuccess = true;
      state.error = action.payload.errors;
      state.message = action.payload.message;
      state.formSort = undefined;
    },
    submitSortError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.errors;
      state.message = action.payload.message;
      state.formSort = undefined;
      state.submitSuccess = false;
    },

    setReceiveParam(state, action: PayloadAction<DropInReceiveParamType>) {
      state.formReceive = action.payload;
    },
    submitReceive(state) {
      state.loading = true;
      state.error = null;
      state.message = '';
      state.submitSuccess = false;
    },
    submitReceiveSuccess(state, action: PayloadAction<GeneralDataResponse>) {
      state.loading = false;
      state.submitSuccess = true;
      state.error = action.payload.errors;
      state.message = action.payload.message;
      state.formReceive = undefined;
    },
    submitReceiveError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.errors;
      state.message = action.payload.message;
      state.formReceive = undefined;
      state.submitSuccess = false;
    },
    resetProduct(state) {
      state.product = [];
    },

    loadDropInOverview(state) {
      state.loading = true;
      state.error = null;
      state.dropInOverview = undefined;
    },
    listOverviewLoaded(
      state,
      action: PayloadAction<{
        pagination: Pagination;
        data: DropInOverviewType[];
      }>,
    ) {
      state.loading = false;
      state.error = null;
      state.dropInOverview = action.payload;
    },
    listOverviewError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.error;
      state.dropInOverview = undefined;
    },

    loadDropCode(state) {
      state.loading = true;
      state.error = null;
      state.dropCodeList = [];
    },
    listDropCodeLoaded(state, action: PayloadAction<DropCodeType[]>) {
      state.loading = false;
      state.error = null;
      state.dropCodeList = action?.payload ?? [];
    },
    listDropCodeError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.error;
      state.dropCodeList = [];
    },

    loadEPRSortForm(state) {
      state.loading = true;
      state.error = null;
      state.eprSortingData = [];
    },
    eprSortFormLoaded(
      state,
      action: PayloadAction<EprPartnerSortFormItemType[]>,
    ) {
      state.loading = false;
      state.error = null;
      state.eprSortingData = action.payload;
    },
    eprSortFormError(state, action: PayloadAction<GeneralDataResponse>) {
      state.loading = false;
      state.error = action.payload.errors;
      state.eprSortingData = [];
    },

    submitEprSortForm(
      state,
      action: PayloadAction<{
        eprWasteList: EprPartnerSortFormItemType[];
        wasteCode: string;
      }>,
    ) {
      state.loading = true;
      state.error = null;
      state.message = '';
      state.eprSortingFormSuccess = false;
      state.eprSortingForm = action.payload;
    },
    submitEprSortFormSuccess(
      state,
      action: PayloadAction<GeneralDataResponse>,
    ) {
      state.loading = false;
      state.eprSortingFormSuccess = true;
      state.error = action.payload.errors;
      state.message = action.payload.message;
      state.eprSortingForm = eprSortingFormDefaultValue;
    },
    submitEprSortFormError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.errors;
      state.message = action.payload.message;
      state.eprSortingForm = eprSortingFormDefaultValue;
    },

    loadEprReceiveForm(state) {
      state.loading = true;
      state.error = null;
      state.eprReceiveForm = [];
    },
    eprReceiveFormLoaded(
      state,
      action: PayloadAction<EprPartnerReceiveFormItemType[]>,
    ) {
      state.loading = false;
      state.error = null;
      state.eprReceiveForm = action.payload;
    },
    eprReceiveFormError(state, action: PayloadAction<GeneralDataResponse>) {
      state.loading = false;
      state.error = action.payload.errors;
      state.eprReceiveForm = [];
    },
  },
});

export const { actions: DropInActions } = slice;

export const useDropInSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: DropInSaga });
  return { actionsDropIn: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useDropInSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
